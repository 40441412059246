<template>
  <b-modal
    id="modal-add-employee"
    size="xl"
    centered
    title="Thêm người lao động"
    cancel-title="Hủy bỏ"
    cancel-variant="outline-primary"
    ok-title="Lưu"
    @ok="handleOk"
    @hidden="resetData"
  >
    <validation-observer ref="rule">
      <b-row>
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group>
            <label>Họ và tên <span class="text-danger">(*)</span></label>
            <validation-provider
              #default="{ errors }"
              name="Họ và tên"
              rules="required"
              :custom-messages="customCode"
            >
              <b-form-input
                v-model="employeeInfo.name"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group>
            <label>Ngày sinh </label>
            <date-picker
              v-model="employeeInfo.birthDay"
              :disabledInput="true"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <small>Giới tính</small>
          <b-form-radio-group
            id="radio-group-1"
            v-model="employeeInfo.gender"
            class="mt-2"
            :options="options"
            name="radio-options"
          />
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group>
            <label>Số CMND/CCCD/HC</label>
            <b-form-input v-model="employeeInfo.identityCard" />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group>
            <label>Trình độ học vấn</label>
            <validation-provider
              ref="code"
              #default="{ errors }"
              :name="$t('users.branch.signUpFromBranch')"
              rules="required"
              :custom-messages="customSelect"
            >
              <v-select
                v-model="employeeInfo.academicLevel"
                :reduce="(value) => value.value"
                label="label"
                :class="{'is-invalid':errors.length > 0 }"
                :options="optionsSelect || []"
                placeholder="Trình độ học vấn"
              >
                <template v-slot:no-options="{ searching }">
                  <template v-if="searching">
                    Không có kết quả.
                  </template>
                  <em
                    v-else
                  >Không có dữ liệu</em>
                </template>
              </v-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group>
            <label>Địa chỉ</label>
            <b-form-input v-model="employeeInfo.address" />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group>
            <label>Số điện thoại</label>
            <b-form-input v-model="employeeInfo.phoneNumber" />
          </b-form-group>
        </b-col>
      </b-row>
    </validation-observer>
    <div class="d-flex mt-2">
      <b-form-checkbox
        v-model="employeeInfo.isJobAdvise"
        class="mr-2"
      >
        Được tư vấn việc làm
      </b-form-checkbox>
      <b-form-checkbox
        v-model="employeeInfo.isJobInterview"
        class="mr-2"
      >
        Được phỏng vấn việc làm
      </b-form-checkbox>
      <b-form-checkbox
        v-model="employeeInfo.isHaveJob"
      >
        Đã nhận được việc làm
      </b-form-checkbox>
    </div>
  </b-modal>
</template>

<script>
import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate/dist/vee-validate.full'
import {
  BRow,
  BCol,
  BFormGroup,
  BModal,
  BFormInput,
  BFormRadioGroup,
  BFormCheckbox,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import DatePicker from '@/components/datepicker/DatePicker.vue'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BModal,
    vSelect,
    BFormInput,
    DatePicker,
    ValidationProvider,
    ValidationObserver,
    BFormRadioGroup,
    BFormCheckbox,
  },
  props: {
    data: {
      type: Object,
      default() {
        return {
          jobSessionId: null,
          workerId: null,
          isBussiness: false,
          name: null,
          gender: true,
          birthDay: null,
          address: null,
          academicLevel: null,
          phoneNumber: null,
          isJobAdvise: false,
          isJobInterview: false,
          isHaveJob: false,
          identityCard: null,
        }
      },
    },
  },
  data() {
    return {
      employeeInfo: JSON.parse(JSON.stringify(this.data)),
      customCode: {
        required: 'Họ và tên là bắt buộc',
      },
      customSelect: {
        required: 'Trình độ học vấn là bắt buộc',
      },
      options: [
        { text: 'Nam', value: true },
        { text: 'Nữ', value: false },
      ],
      optionsCheckbox: [
        { text: 'Được tư vấn việc làm', value: false },
        { text: 'Được phỏng vấn việc làm', value: false },
        { text: 'Đã nhận được việc làm', value: false },
      ],
      optionsSelect: [
        {
          label: 'Đại học',
          value: 'University',
        },
        {
          label: 'Cao đẳng',
          value: 'College',
        },
        {
          label: 'Trung cấp',
          value: 'Intermediate',
        },
        {
          label: 'Sơ cấp nghề',
          value: 'Primary',
        },
        {
          label: 'Chưa qua đào tạo',
          value: 'None',
        },
      ],
    }
  },
  watch: {
    data: {
      deep: true,
      handler(val) {
        this.employeeInfo = JSON.parse(JSON.stringify(val))
      },
    },
  },
  methods: {
    handleOk(e) {
      e.preventDefault()
      this.$refs.rule.validate().then(async success => {
        if (success) {
          this.employeeInfo.jobSessionId = this.$route.params.id
          this.$bvModal.hide('modal-add-employee')
          this.$emit('update', this.employeeInfo)
        }
      })
    },
    resetData() {
      this.employeeInfo = JSON.parse(JSON.stringify(this.data))
    },
  },
}
</script>
