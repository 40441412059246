var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"modal-add-business","size":"lg","title":"Thêm doanh nghiệp","cancel-title":"Hủy bỏ","cancel-variant":"outline-primary","ok-disabled":_vm.disabledOk,"ok-title":"Lưu"},on:{"show":_vm.getListBusinessModal,"ok":_vm.handleOk}},[_c('div',{staticClass:"header-action"},[_c('search',{on:{"change":_vm.search}})],1),_c('vue-good-table',{ref:"business-modal-table",staticClass:"mh-60",attrs:{"columns":_vm.columns,"rows":_vm.dataList || [],"select-options":{
      enabled: true,
      vertialAlignTop: true,
      selectOnCheckboxOnly: true,
      selectionInfoClass: 'custom-class',
      selectionText: 'rows selected',
      clearSelectionText: 'clear',
      disableSelectInfo: true,
      selectAllByGroup: true,
    }},scopedSlots:_vm._u([{key:"table-column",fn:function(props){return [_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(props.column.label)+" ")])]}},{key:"table-row",fn:function(props){return [(props.column.field === 'action')?_c('span',[_c('span',{staticClass:"ml-2",on:{"click":function($event){return _vm.deleteItem(props.row.id)}}},[_c('feather-icon',{staticClass:"text-body",attrs:{"icon":"Trash2Icon","size":"18"}})],1)]):(props.column.field === 'code')?_c('span',[_c('b-badge',{attrs:{"variant":"light-primary"}},[_vm._v(" "+_vm._s(props.row.code)+" ")])],1):_vm._e()]}}])}),_c('my-pagination',{attrs:{"totalItems":_vm.totalRecord},on:{"pageClick":_vm.handlePageClick}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }