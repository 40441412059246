<template>
  <div class="page-container-table">
    <button-all-header
      :contentBtnAdd="'Thêm người lao động'"
      :showBtnMultiDelete="showBtnMultiDelete"
      :hideExportFile="false"
      @clickDelete="deleteItems"
      @clickAdd="addEmployee"
      @search="search($event)"
    />
    <vue-good-table
      ref="employee-table"
      class="mh-60"
      style-class="vgt-table"
      :columns="columns"
      :rows="dataList || []"
      :select-options="{
        enabled: true,
        vertialAlignTop: true,
        selectOnCheckboxOnly: true,
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true,
        selectAllByGroup: true,
      }"
      @on-selected-rows-change="selectRowTable"
    >

      <template
        slot="table-column"
        slot-scope="props"
      >
        <span class="text-nowrap">
          {{ props.column.label }}
        </span>
      </template>

      <template
        slot="table-row"
        slot-scope="props"
      >
        <span v-if="props.column.field === 'action'">
          <span @click="getDetailEmployee(props.row.id)">
            <feather-icon
              icon="Edit3Icon"
              size="18"
              class="text-body"
            />
          </span>
          <span
            class="ml-2"
            @click="deleteItem(props.row.id)"
          >
            <feather-icon
              icon="Trash2Icon"
              size="18"
              class="text-body"
            />
          </span>
        </span>
        <div
          v-else-if="props.column.field === 'isJobInterview'"
          class="d-flex justify-content-center"
        >
          <feather-icon
            v-if="props.row.isJobInterview"
            class="text-success"
            icon="CheckIcon"
            size="18"
          />
          <feather-icon
            v-else
            class="text-danger"
            icon="XIcon"
            size="18"
          />
        </div>
        <div
          v-else-if="props.column.field === 'isHaveJob'"
          class="d-flex justify-content-center"
        >
          <feather-icon
            v-if="props.row.isHaveJob"
            class="text-success"
            icon="CheckIcon"
            size="18"
          />
          <feather-icon
            v-else
            class="text-danger"
            icon="XIcon"
            size="18"
          />
        </div>
        <div
          v-else-if="props.column.field === 'isJobAdvise'"
          class="d-flex justify-content-center"
        >
          <feather-icon
            v-if="props.row.isJobAdvise"
            class="text-success"
            icon="CheckIcon"
            size="18"
          />
          <feather-icon
            v-else
            class="text-danger"
            icon="XIcon"
            size="18"
          />
        </div>
        <div
          v-else-if="props.column.field === 'birthDay'"
          class="d-flex justify-content-center"
        >
          {{ props.row.birthDay | formatDate }}
        </div>
      </template>
    </vue-good-table>

    <!-- pagination -->
    <my-pagination
      :totalItems="totalRecord"
      @pageClick="handlePageClick"
    />
    <div class="p-2">
      <b-button
        variant="outline-primary"
        @click="$router.push({ name: 'session-employment' })"
      >
        Quay lại
      </b-button>
    </div>
    <modal-add-employee
      :data="employeeInfo"
      @update="updateEmployee"
    />

    <confirm-modal
      id="confirm-modal"
      :title="''"
      content="Bạn có chắc chắn muốn xóa người lao động khỏi phiên giao dịch không"
      @accept="acceptDelete"
    />
  </div>
</template>

<script>
import {
  BBadge,
  BMedia,
  BDropdown,
  BDropdownItem,
  BButton,
  VBTooltip,
} from 'bootstrap-vue'
import VueGoodTable from '@/components/table/VueGoodTable.vue'
import ButtonComponent from '@/components/button/ButtonComponent.vue'
import MyPagination from '@/components/pagination/MyPagination.vue'
import UserAvatar from '@/components/avatar/UserAvatar.vue'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import Search from '@/components/search/Search.vue'
import ConfirmModal from '@/components/confirm-modal/ConfirmModal.vue'
import ButtonAllHeader from '@/views/common/ButtonAllHeader.vue'
import ModalAddEmployee from './ModalAddEmployee.vue'
import store from '../../../store'

export default {
  components: {
    VueGoodTable,
    BBadge,
    BMedia,
    MyPagination,
    UserAvatar,
    BDropdown,
    BDropdownItem,
    FeatherIcon,
    Search,
    ButtonComponent,
    ConfirmModal,
    BButton,
    ButtonAllHeader,
    ModalAddEmployee,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      queryParams: {
        jobSessionId: null,
        key: '',
        pageSize: 10,
        pageNumber: 1,
      },
      columns: [
        {
          label: 'Họ và tên',
          field: 'name',
          sortable: false,
        },
        {
          label: 'Ngày sinh',
          field: 'birthDay',
          sortable: false,
        },
        {
          label: 'Địa chỉ',
          field: 'address',
          sortable: false,
        },
        {
          label: 'Trình độ học vấn',
          field: 'academicLevel',
          sortable: false,
        },
        {
          label: 'Tư vấn việc làm',
          field: 'isJobAdvise',
          sortable: false,
        },
        {
          label: 'Phỏng vấn việc làm',
          field: 'isJobInterview',
          sortable: false,
        },
        {
          label: 'Nhận được việc làm',
          field: 'isHaveJob',
          sortable: false,
        },
        {
          label: 'Chức năng',
          field: 'action',
          sortable: false,
          width: '175px',
        },
      ],
      dataList: [],
      totalRecord: 0,
      showBtnMultiDelete: false,
      deletedIds: [],
      employeeInfo: undefined,
    }
  },
  created() {
    if (this.$route.params.id) {
      this.queryParams.jobSessionId = this.$route.params.id
    }
    this.getListEmployee()
  },
  methods: {
    async getListEmployee() {
      this.$showLoading()
      const { data } = await store.getListEmployee(this.queryParams)
      if (data) {
        this.dataList = data.pageLists
        this.totalRecord = data.totalRecord
      }
      this.$hideLoading()
    },
    handlePageClick(pageNumber, pageSize) {
      this.queryParams = {
        ...this.queryParams,
        pageNumber,
        pageSize,
      }
      this.getListEmployee()
    },
    search(val) {
      this.queryParams = {
        ...this.queryParams,
        pageNumber: 1,
        key: val,
      }
      this.getListEmployee()
    },
    selectRowTable(val) {
      const { selectedRows } = val
      this.showBtnMultiDelete = selectedRows.length > 0
    },
    deleteItem(id) {
      this.deletedIds = [id]
      this.$bvModal.show('confirm-modal')
    },
    deleteItems() {
      this.deletedIds = this.$refs['employee-table'].selectedRows.map(item => item.id)
      this.$bvModal.show('confirm-modal')
    },
    async acceptDelete() {
      const params = {
        ids: this.deletedIds,
        jobSessionId: this.$route.params.id,
      }
      const res = await store.removeEmployeeFromSession(params)
      if (res.success) {
        this.$root.$bvToast.toast('Xóa người lao động khỏi phiên giao dịch thành công', {
          title: 'Thông báo',
          variant: 'success',
          toaster: this.$toastPosition,
          solid: true,
        })
        this.getListEmployee()
      }
    },
    addEmployee() {
      this.employeeInfo = undefined
      this.$bvModal.show('modal-add-employee')
    },
    async updateEmployee(info) {
      if (!info.id) {
        const res = await store.addEmployee(info)
      } else {
        const res = await store.editEmployee(info)
      }
      this.getListEmployee()
    },
    async getDetailEmployee(id) {
      const res = await store.getDetailEmployee(id)
      this.employeeInfo = res
      this.$bvModal.show('modal-add-employee')
    },
  },
}
</script>
