<template>
  <div class="page-container-table">
    <button-all-header
      :contentBtnAdd="'Thêm doanh nghiệp'"
      :showBtnMultiDelete="showBtnMultiDelete"
      :hideImportFile="false"
      :hideExportFile="false"
      :hideDowload="false"
      @clickDelete="deleteItems"
      @clickAdd="$bvModal.show('modal-add-business')"
      @search="search($event)"
    />
    <vue-good-table
      ref="business-table"
      class="mh-60"
      style-class="vgt-table"
      :columns="columns"
      :rows="dataList || []"
      :select-options="{
        enabled: true,
        vertialAlignTop: true,
        selectOnCheckboxOnly: true,
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true,
        selectAllByGroup: true,
      }"
      @on-selected-rows-change="selectRowTable"
    >
      <!-- Slot: Table Column -->
      <template
        slot="table-column"
        slot-scope="props"
      >
        <span class="text-nowrap">
          {{ props.column.label }}
        </span>
      </template>

      <!-- Slot: Table Row -->
      <template
        slot="table-row"
        slot-scope="props"
      >
        <span v-if="props.column.field === 'action'">
          <span
            class="ml-2"
            @click="deleteItem(props.row.id)"
          >
            <feather-icon
              icon="Trash2Icon"
              size="18"
              class="text-body"
            />
          </span>
        </span>
        <span v-else-if="props.column.field === 'code'">
          <b-badge variant="light-primary">
            {{ props.row.code }}
          </b-badge>
        </span>
      </template>
    </vue-good-table>

    <!-- pagination -->
    <my-pagination
      :totalItems="totalRecord"
      @pageClick="handlePageClick"
    />
    <div class="p-2">
      <b-button
        variant="outline-primary"
        @click="$router.push({ name: 'session-employment' })"
      >
        Quay lại
      </b-button>
    </div>
    <modal-add-business @addSuccess="getListBusiness" />
    <confirm-modal
      id="confirm-modal"
      :title="''"
      content="Bạn có chắc chắn muốn xóa doanh nghiệp khỏi phiên giao dịch không"
      @accept="acceptDelete"
    />
  </div>
</template>

<script>
import {
  BBadge,
  BMedia,
  BDropdown,
  BDropdownItem,
  BButton,
  VBTooltip,
} from 'bootstrap-vue'
import VueGoodTable from '@/components/table/VueGoodTable.vue'
import ButtonComponent from '@/components/button/ButtonComponent.vue'
import MyPagination from '@/components/pagination/MyPagination.vue'
import UserAvatar from '@/components/avatar/UserAvatar.vue'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import Search from '@/components/search/Search.vue'
import ConfirmModal from '@/components/confirm-modal/ConfirmModal.vue'
import ButtonAllHeader from '@/views/common/ButtonAllHeader.vue'
import ModalAddBusiness from './ModalAddBusiness.vue'
import store from '../../../store'

export default {
  components: {
    VueGoodTable,
    BBadge,
    BMedia,
    MyPagination,
    UserAvatar,
    BDropdown,
    BDropdownItem,
    FeatherIcon,
    Search,
    ButtonComponent,
    ConfirmModal,
    BButton,
    ButtonAllHeader,
    ModalAddBusiness,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      queryParams: {
        jobSessionId: null,
        key: '',
        pageSize: 10,
        pageNumber: 1,
      },
      columns: [
        {
          label: 'Mã doanh nghiệp',
          field: 'code',
          sortable: false,
        },
        {
          label: 'Tên doanh nghiệp',
          field: 'name',
          sortable: false,
        },
        {
          label: 'Địa điểm',
          field: 'address',
          sortable: false,
        },
        {
          label: 'Chức năng',
          field: 'action',
          sortable: false,
          width: '175px',
        },
      ],
      dataList: [],
      totalRecord: 0,
      deletedIds: [],
      showBtnMultiDelete: false,
    }
  },
  created() {
    if (this.$route.params.id) {
      this.queryParams.jobSessionId = this.$route.params.id
    }
    this.getListBusiness()
  },
  methods: {
    async getListBusiness() {
      this.$showLoading()
      const { data } = await store.getListBusiness(this.queryParams)
      if (data) {
        this.dataList = data.pageLists
        this.totalRecord = data.totalRecord
      }
      this.$hideLoading()
    },
    handlePageClick(pageNumber, pageSize) {
      this.queryParams = {
        ...this.queryParams,
        pageNumber,
        pageSize,
      }
      this.getListBusiness()
    },
    search(val) {
      this.queryParams = {
        ...this.queryParams,
        pageNumber: 1,
        key: val,
      }
      this.getListBusiness()
    },
    selectRowTable(val) {
      const { selectedRows } = val
      this.showBtnMultiDelete = selectedRows.length > 0
    },
    deleteItem(id) {
      this.deletedIds = [id]
      this.$bvModal.show('confirm-modal')
    },
    deleteItems() {
      this.deletedIds = this.$refs['business-table'].selectedRows.map(item => item.id)
      this.$bvModal.show('confirm-modal')
    },
    async acceptDelete() {
      const params = {
        ids: this.deletedIds,
        jobSessionId: this.$route.params.id,
      }
      const res = await store.deleteBusiness(params)
      if (res.success) {
        this.$root.$bvToast.toast('Xóa doanh nghiệp khỏi phiên giao dịch thành công', {
          title: 'Thông báo',
          variant: 'success',
          toaster: this.$toastPosition,
          solid: true,
        })
        this.getListBusiness()
      }
    },
  },
}
</script>
