<template>
  <b-modal
    id="modal-add-business"
    size="lg"
    title="Thêm doanh nghiệp"
    cancel-title="Hủy bỏ"
    cancel-variant="outline-primary"
    :ok-disabled="disabledOk"
    ok-title="Lưu"
    @show="getListBusinessModal"
    @ok="handleOk"
  >
    <div class="header-action">
      <search @change="search" />
    </div>
    <vue-good-table
      ref="business-modal-table"
      class="mh-60"
      :columns="columns"
      :rows="dataList || []"
      :select-options="{
        enabled: true,
        vertialAlignTop: true,
        selectOnCheckboxOnly: true,
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true,
        selectAllByGroup: true,
      }"
    >
      <template
        slot="table-column"
        slot-scope="props"
      >
        <span class="text-nowrap">
          {{ props.column.label }}
        </span>
      </template>

      <template
        slot="table-row"
        slot-scope="props"
      >
        <span v-if="props.column.field === 'action'">
          <span
            class="ml-2"
            @click="deleteItem(props.row.id)"
          >
            <feather-icon
              icon="Trash2Icon"
              size="18"
              class="text-body"
            />
          </span>
        </span>
        <span v-else-if="props.column.field === 'code'">
          <b-badge variant="light-primary">
            {{ props.row.code }}
          </b-badge>
        </span>
      </template>
    </vue-good-table>
    <my-pagination
      :totalItems="totalRecord"
      @pageClick="handlePageClick"
    />
  </b-modal>
</template>

<script>
import {
  BBadge,
  BModal,
} from 'bootstrap-vue'
import Search from '@/components/search/Search.vue'
import VueGoodTable from '@/components/table/VueGoodTable.vue'
import MyPagination from '@/components/pagination/MyPagination.vue'
import store from '../../../store'

export default {
  components: {
    VueGoodTable,
    MyPagination,
    Search,
    BBadge,
    BModal,
  },
  data() {
    return {
      queryParams: {
        jobSessionId: null,
        key: '',
        pageSize: 10,
        pageNumber: 1,
      },
      columns: [
        {
          label: 'Mã doanh nghiệp',
          field: 'code',
          sortable: false,
        },
        {
          label: 'Tên doanh nghiệp',
          field: 'name',
          sortable: false,
        },
        {
          label: 'Địa điểm',
          field: 'address',
          sortable: false,
          width: '175px',
        },
      ],
      dataList: [],
      totalRecord: 0,
      disabledOk: false,
    }
  },
  created() {
    this.queryParams.jobSessionId = this.$route.params.id
  },
  methods: {
    async getListBusinessModal() {
      this.$showLoading()
      const { data } = await store.getListBusinessModal(this.queryParams)
      this.dataList = data.pageLists
      this.totalRecord = data.totalRecord
      this.$hideLoading()
    },
    handlePageClick(pageNumber, pageSize) {
      this.queryParams = {
        ...this.queryParams,
        pageNumber,
        pageSize,
      }
      this.getListBusinessModal()
    },
    search(val) {
      this.queryParams = {
        ...this.queryParams,
        pageNumber: 1,
        key: val,
      }
      this.getListBusinessModal()
    },
    async handleOk(e) {
      e.preventDefault()
      const bussinessIds = this.$refs['business-modal-table'].selectedRows.map(item => item.id)
      if (bussinessIds.length === 0) {
        this.$root.$bvToast.toast('Vui lòng lựa chọn ít nhất 1 người dùng', {
          title: 'Thông báo',
          variant: 'warning',
          toaster: this.$toastPosition,
          solid: true,
        })
        return
      }
      this.disabledOk = true
      const params = {
        jobSessionId: this.$route.params.id,
        bussinessIds,
      }
      const res = await store.addBusinessToSession(params)
      if (res.success) {
        this.$bvModal.hide('modal-add-business')
        this.$root.$bvToast.toast('Thêm doanh nghiệp thành công', {
          title: 'Thông báo',
          variant: 'success',
          toaster: this.$toastPosition,
          solid: true,
        })
        this.$emit('addSuccess')
      }
      this.disabledOk = false
    },
  },
}
</script>
