<template>
  <div id="addBusiness">
    <my-tabs
      :listTabs="listTabs"
      defaultActive="info"
      typeCss="active-full"
      @setActive="setActive"
    />
    <div>
      <info
        v-if="isRendered('info')"
        class="tab-pane"
        :class="{ active: activeTab === 'info' }"
      />
      <business
        v-if="isRendered('business')"
        class="tab-pane"
        :class="{ active: activeTab === 'business' }"
      />
      <employee
        v-if="isRendered('employee')"
        class="tab-pane"
        :class="{ active: activeTab === 'employee' }"
      />
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import { BButton } from "bootstrap-vue";
import MyTabs from "@/components/tabs/MyTabs.vue";
import { axiosApiInstance } from "@/@core/auth/jwt/axiosApiInstance";
import Info from "./components/info/Info.vue";
import Business from "./components/business/Business.vue";
import Employee from "./components/employee/Employee.vue";
export default {
  components: {
    BButton,
    MyTabs,
    Info,
    Business,
    Employee,
  },
  data() {
    return {
      activeTab: null,
      listTabs: [
        {
          key: "info",
          title: "Thông tin",
          icon: "InfoIcon",
          isRendered: false,
        },
        {
          key: "business",
          title: "Doanh nghiệp",
          icon: "BriefcaseIcon",
          isRendered: false,
        },
        {
          key: "employee",
          title: "Người lao động",
          icon: "UserIcon",
          isRendered: false,
        },
      ],
      dataInforBusiness: {},
    };
  },
  watch: {
    $route(to, from) {
      if (to && to.params.type) {
        this.activeTab = to.params.type;
        const tab = this.listTabs.find((x) => x.key === this.activeTab);
        if (tab) tab.isRendered = true;
      }
    },
  },
  created() {
    if (this.activeTab === null) {
      this.activeTab = "info";
      this.listTabs[0].isRendered = true;
    }
    if(!this.$route.params.id) {
      this.listTabs[1].isDisabled = true
      this.listTabs[2].isDisabled = true
    }
  },
  methods: {
    isRendered(key) {
      const tab = this.listTabs.find((x) => x.key === key);
      if (tab) return tab.isRendered;
      return false;
    },
    setActive(activeTab) {
      this.activeTab = activeTab;
      const tab = this.listTabs.find((x) => x.key === activeTab);
      if (tab) tab.isRendered = true;
    },
  },
};
</script>