<template>
  <div class="page-container">
    <validation-observer ref="rule">
      <b-row>
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group>
            <label>Mã phiên giao dịch <span class="text-danger">(*)</span></label>
            <validation-provider
              #default="{ errors }"
              name="Mã phiên giao dịch"
              rules="required"
              :custom-messages="customCode"
            >
              <b-form-input
                v-model="queryParams.code"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group>
            <label>Ngày thực hiện giao dịch
              <span class="text-danger">(*)</span></label>
            <validation-provider
              #default="{ errors }"
              name="dateSession"
              rules="required"
              :custom-messages="dateSession"
            >
              <date-picker
                v-model="queryParams.dateSession"
                :disabledInput="true"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group>
            <label>Địa điểm</label>
            <b-form-input v-model="queryParams.location" />
          </b-form-group>
        </b-col>
      </b-row>
    </validation-observer>
    <div class="mt-2">
      <b-button
        class="mr-1"
        variant="primary"
        @click="updateSession"
      >
        Lưu lại
      </b-button>
      <b-button
        class="mr-1"
        variant="outline-primary"
        @click="$router.push({name: 'session-employment'})"
      >
        Quay lại
      </b-button>
    </div>
  </div>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormInput, BButton,
} from 'bootstrap-vue'
import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate/dist/vee-validate.full'
import DatePicker from '@/components/datepicker/DatePicker.vue'
import store from '../../../store'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    DatePicker,
    BButton,
  },
  data() {
    return {
      queryParams: {
        code: '',
        dateSession: '',
        location: '',
      },
      customCode: {
        required: 'Mã phiên giao dịch là bắt buộc',
      },
      dateSession: {
        required: 'Ngày thực hiện giao dịch là bắt buộc',
      },
    }
  },
  created() {
    if (this.$route.params.id) {
      this.getDetailSession()
    }
  },
  methods: {
    async updateSession() {
      this.$refs.rule.validate().then(async success => {
        if (success) {
          let message = ''
          let variant = ''
          if (!this.$route.params.id) {
            const res = await store.addSession(this.queryParams)
            if (res.success) {
              message = 'Thêm phiên giao dịch việc làm thành công'
              variant = 'success'
              this.$router.push({ name: 'session-employment' })
            } else {
              message = 'Thêm phiên giao dịch việc làm thất bại'
              variant = 'danger'
            }
          } else {
            const res = await store.editSession(this.queryParams)
            if (res.success) {
              message = 'Chỉnh sửa phiên giao dịch việc làm thành công'
              variant = 'success'
            } else {
              message = 'Chỉnh sửa phiên giao dịch việc làm thất bại'
              variant = 'danger'
            }
          }
          this.$root.$bvToast.toast(this.$t(message), {
            title: this.$t('common.notification'),
            variant,
            toaster: this.$toastPosition,
            solid: true,
          })
        }
      })
    },
    async getDetailSession() {
      const res = await store.getDetailSession(this.$route.params.id)
      this.queryParams = res
    },
  },
}
</script>

<style>
</style>
